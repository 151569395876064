<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import draggable from 'vuedraggable'

	export default {
		name: "MrProject",
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "MrProject",
				hero: {},
				PageName: 'Selosin Indonesia Landl Residence',
				main:{},
				data:{data:[]},
				advInput:{},
            	adv:[],
				unit:[],
				inputUnit:{},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'(v) {
				if (!v) return
				this.search()
			},
		},
		methods: {
			endDrag() {
				BOGen.apirest('/' + this.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},

			submitFormHero(e, callback){
	          if(e&&e.btnLoading()) return;
	          this.hero.type = 'hero'
	          this.hero.project = App.$route.params.id
	          BOGen.apirest("/"+this.Name, this.hero, (err, resp)=>{
	            if(e) e.btnUnloading()
	            if(err){
	              if(err.status == 422){
	                Object.keys(err.responseJSON).forEach((k)=>{
	                  $("[error="+k+"]").html("<label class='error'>"+err.responseJSON[k][0]+"</label>")
	                })
	              }
	              return
	            }
	            if(resp.error){
	              if(this.errorFormCallback) return this.errorFormCallback(resp);
	              if(callback) return callback(resp);
	              return Gen.info(resp.message, "danger",1000,'.info-hero')
	            }
	            if(resp.success){
	              if(this.successFormCallback) return this.successFormCallback(resp);
	              if(callback) return callback(resp);
	              return Gen.info(resp.message, "success", 1000,'.info-hero').then(()=>{
	                this.$router.push({name:this.Name,params:{id:this.$route.params.id}})
	              })
	            }
	          },"POST")
	        }, 

			editAdv(v){
            $('#collapseAdv').addClass('show')
            this.advInput=v
            this.advInput.type='editAdv'
        },
        addAdv(){
			$('#collapseAdv').addClass('show')
            this.advInput={mpa_is_active:'Y',type:'addAdv'}
        },

        submitFormAdventage(e, callback){
          if(e&&e.btnLoading()) return;
          this.advInput.mpa_project = App.$route.params.id
          BOGen.apirest("/"+this.Name, this.advInput, (err, resp)=>{
            if(e) e.btnUnloading()
            if(err){
              if(err.status == 422){
                Object.keys(err.responseJSON).forEach((k)=>{
                  $("[error="+k+"]").html("<label class='error'>"+err.responseJSON[k][0]+"</label>")
                })
              }
              return
            }
            if(resp.error){
              if(this.errorFormCallback) return this.errorFormCallback(resp);
              if(callback) return callback(resp);
              return Gen.info(resp.message, "danger",1000,'.info-adv')
            }
            if(resp.success){
              if(this.successFormCallback) return this.successFormCallback(resp);
              if(callback) return callback(resp);
              setTimeout(()=>{
                $('[href="#collapseAdv"]').click();
                this.refreshData()
              },2000)
              return Gen.info(resp.message, "success", 1000,'.info-adv').then(()=>{
                this.$router.push({name:this.Name,params:{id:this.$route.params.id}})
              })
            }
          },"POST")
        },  
        deleteAdv(id){
          var v = id
          swal({
            title: "Are you sure to delete "+this.ObjectName+"?",
            text:"",
            icon: "warning",
            buttons: ["No, Cancel!", "Yes, Sure!"],
            dangerMode: true,
          }).then((ok) => {
            if (ok) {
              BOGen.apirest("/"+this.Name, {type:"deleteAdv",id:v},()=>{
                swal("Deleted!", "Information has been deleted.", "success");
                this.refreshData()
              }, "POST")
            }
          });
        }, 

		endDragAdv() {
            BOGen.apirest('/' + this.Name, {
                data: this.adv,
                type: 'sortAdv'
            }, (err, resp) => {
                console.log(resp)
            }, "POST");
        },      
        endDragFacilities() {
            BOGen.apirest('/' + this.Name, {
                data: this.facilities,
                type: 'sortFacilities'
            }, (err, resp) => {
                console.log(resp)
            }, "POST");
        },
        addUnit(){
            this.inputUnit ={}
            this.inputUnit.mpu_is_active='Y'
            this.inputUnit.type='addUnit'
            this.inputUnit.spec= [{spc_img:'',spc_title:''}]
            $("#collapseExample").addClass('show');
        },
        editUnit(v){
            console.log(v)
            this.inputUnit=v
            this.inputUnit.type='editUnit'
            $("#collapseExample").addClass('show');
        },
        delUnit(id){
          var v = id
          swal({
            title: "Are you sure to delete "+this.ObjectName+"?",
            text:"",
            icon: "warning",
            buttons: ["No, Cancel!", "Yes, Sure!"],
            dangerMode: true,
          }).then((ok) => {
            if (ok) {
              BOGen.apirest("/"+this.Name, {type:"deleteUnit",id:v},()=>{
                swal("Deleted!", "Information has been deleted.", "success");
                this.refreshData()
              }, "POST")
            }
          });
        },        
        submitUnit(e, callback){
          if(e&&e.btnLoading()) return;
          this.inputUnit.mpu_project = App.$route.params.id
          BOGen.apirest("/"+this.Name, this.inputUnit, (err, resp)=>{
            if(e) e.btnUnloading()
            if(err){
              if(err.status == 422){
                Object.keys(err.responseJSON).forEach((k)=>{
                  $("[error="+k+"]").html("<label class='error'>"+err.responseJSON[k][0]+"</label>")
                })
              }
              return
            }
            if(resp.error){
              if(this.errorFormCallback) return this.errorFormCallback(resp);
              if(callback) return callback(resp);
              return Gen.info(resp.message, "danger",1000,'.info-unit')
            }
            if(resp.success){
              if(this.successFormCallback) return this.successFormCallback(resp);
              if(callback) return callback(resp);
              setTimeout(()=>{
                $('#collapseExample').addClass('show');
                this.refreshData()
              },2000)
              return Gen.info(resp.message, "success", 1000,'.info-unit').then(()=>{
                this.$router.push({name:this.Name,params:{id:this.$route.params.id}})
              })
            }
          },"POST")
        },     
        endDragUnit() {
            BOGen.apirest('/' + this.Name, {
                data: this.unit,
                type: 'sortUnit'
            }, (err, resp) => {
                console.log(resp)
            }, "POST");
        },  
		addSpec(){
            this.inputUnit.spec.push({spc_img:'',spc_title:''})
        },
        delSpec(k){
            this.inputUnit.spec.splice(k,1)
        }, 
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="card col-12">
				<div class="card-body">
					<vue-tabs>
						<v-tab title="Project List">
							<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
								<div class="card">
									<div class="card-body">
										<VForm @resp="search">
											<div class="row">
												<div class="col-sm-5">
												</div>
												<div class="col-sm-3">
												</div>
												<div class="col-sm-3">
													<div class="form-group mb-0">
														<div class="input-group">
															<input type="text" v-model="filter.search" v-on:keyup.enter="search"
																class="form-control" placeholder="Search...">
															<div class="input-group-append">
																<button class="btn btn-info" type="button" @click="search()"><i
																		class="fas fa-search"></i></button>
															</div>
														</div>
													</div>
												</div>
												<div class="col-sm-1">
													<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
												</div>
											</div>
										</VForm>
									</div>
									<div class="card-body">
										<div class="col-md-12 mb-3">
											<h5>List Project</h5>
										</div>
										<draggable class="row" v-model="data.data" @end="endDrag" style="cursor:pointer;">					
											<div class="col-lg-20 col-md-4 col-xs-6" v-for="(v,k) in data.data" :key="k">
												<div class="card border project-item">
													<div class="card-body position-relative" style="cursor: move;">
														<div class="product-img">
															<img :src="uploader(v.mp_banner_square)" style="width: 100%;">
														</div>
														<span class="label label-success" v-if="v.mp_is_active == 'Y'">Active</span>
														<span class="label label-danger" v-else>Inactive</span>
														<span v-if="v.mp_featured=='Y'" style="position: absolute;left: auto;right: 8px;" class="label label-info">Featured</span>
														<p class="mb-0 mt-2">{{v.mp_title}}</p>
													</div>
													<div class="card-footer action-footer" style="padding: 0">
														<div class="d-flex justify-content-around">
															<router-link role="button" :to="{name:Name,params:{id:v.id}}"
																style="padding: 8px;display: block;" v-tooltip="'Project Settings'" class="">
																<span><i class="fas fa-pencil-alt"></i></span></router-link>
															<a v-if="v.mp_id>0" href="javascript:void(0)" style="padding: 8px;display: block;"
																@click="deleteItem($event,k)" v-tooltip="'Remove Project'"><i
																	class="ti-trash"></i></a>
														</div>
													</div>
												</div>
											</div>
										</draggable>
									</div>
									<div class="panel-body mt-3 mb-3" v-if="NotFound">
										<div class="text-center">
											<hr>
											<h3 class="tc">{{NotFound}}</h3>
										</div>
									</div>
									<div class="panel-body">
										<Pagination  class="mb-0 justify-content-end" :data="data.data" :limit="3" @pagination-change-page="onPaging"></Pagination>
									</div>
								</div>
							</div>
						</v-tab>
					</vue-tabs>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-md-12 mb-3" style="border-bottom: 1px solid #ddd;" v-if="row.id != -1">
							<div class="row">
								<div class="col-md-4">
									<BoField name="mp_banner_square">
										<Uploader name="mp_banner_square" type="thumbunit" uploadType="cropping" :param="{thumbnail:true}"
											v-model="row.mp_banner_square"></Uploader>
									</BoField>
								</div>
								<div class="col-md-4">
									<BoField name="mp_banner_width">
										<Uploader name="mp_banner_width" type="slideshow" uploadType="cropping" :param="{thumbnail:true}"
											v-model="row.mp_banner_width"></Uploader>
									</BoField>
								</div>
								<div class="col-md-4">							
									<BoField name="mp_brosur">
										<Uploader name="mp_brosur" type="brosur" :param="{thumbnail:false}"
											v-model="row.mp_brosur"></Uploader>
									</BoField>
								</div>
								<div class="col-md-6">
									<BoField name="mp_title" v-model="row.mp_title"></BoField>
									<BoField name="mp_desc" :label="'Project Description'" >
										<CKEditor name="mp_desc" v-bind="validation('mp_desc')" class="form-control" v-model="row.mp_desc"></CKEditor>
									</BoField>
								</div>
								<div class="col-md-6">
									<BoField name="mp_terjual" :attr="{type:'number'}" v-model="row.mp_terjual"></BoField>
									<BoField name="mp_dibangun" :attr="{type:'number'}" v-model="row.mp_dibangun"></BoField>
									<BoField name="mp_address">
										<textarea name="mp_address" rows="8" v-model="row.mp_address" class="form-control"></textarea>
									</BoField>
									<BoField name="mp_is_active">
										<div class="row">
											<radio name="mp_is_active" v-model="row.mp_is_active" option="Y"
												:attr="validation('mp_is_active')">Active</radio>
											<radio name="mp_is_active" v-model="row.mp_is_active" option="N">Inactive
											</radio>
										</div>
									</BoField>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-rounded btn-info btn-outline btn-1e btn-loading">Save
									Project</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>


		</div>

		<div class="card" v-if="$route.params.id">
			<div class="card-body">
				<h5 class="card-title">Mengapa Memilih</h5>
				<draggable v-model="adv" class="row" tag="tbody" @end="endDragAdv">

					<div style="cursor:move;" class="col-md-2" :id="'dat'+va.as_id" v-for="(va,ka) in adv"
						:key="ka">
						<div class="wrap_slider_img">
							<img :src="uploader(va.mpa_img)" class="img-responsive" />
							<div class="slider_name">
								<p>{{va.mpa_title}}</p>
							</div>
							<a data-toggle="collapse" @click="editAdv(va)" href="javascript:;"
								class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
							<a href="javascript:;" @click="deleteAdv(va.mpa_id)"
								class="bullet_delete"><i class="far fa-trash-alt"></i></a>
							<span class="label label-success" v-if="va.mpa_is_active == 'Y'">Active</span>
							<span class="label label-danger" v-else>Inactive</span>
						</div>
					</div>

					<div class="col-md-2">
						<a class="wrap_upload" data-toggle="collapse" @click="addAdv" href="#collapseAdv"
							role="button" aria-expanded="false" aria-controls="collapseAdv">
							<div class="ic_wrap">
								<i class="fas fa-plus"></i>
								<p>New Advantage</p>
							</div>
						</a>
					</div>
				</draggable>
				<VForm @resp="submitFormAdventage" method="post">
					<div class="row collapse mt-4" id="collapseAdv">
						<div class="col-12 info-adv"></div>
						<div class="col-md-4 mb-3">
							<BoField name="mpa_img" mandatory>
								<Uploader :param="{thumbnail:true}" name="mpa_img" v-model="advInput.mpa_img"
									type="adventage" uploadType="upload"></Uploader>
							</BoField>
						</div>
						<div class="col-md-8 mb-3">
							<div class="row">
								<div class="col-md-12">
									<div class="panel_group">
										<div class="panel_heading">Content</div>
										<div class="panel_body">
											<div class="row">
												<div class="col-md-9">
													<BoField name="mpa_title" v-model="advInput.mpa_title">
													</BoField>
												</div>
													<div class="col-md-9">
													<BoField name="mpa_desc">
														<textarea v-model="advInput.mpa_desc" rows="4"
															class="form-control"></textarea>
													</BoField>
												</div>  
												<div class="col-md-9">
													<BoField name="mpa_is_active">
														<div class="row">
															<div class="col-3">
																<radio name="mpa_is_active"
																	v-model="advInput.mpa_is_active"
																	option="Y"
																	:attr="validation('mpa_is_active')">
																	Active</radio>
															</div>
															<div class="col-3">
																<radio name="mpa_is_active"
																	v-model="advInput.mpa_is_active"
																	option="N">
																	Inactive</radio>
															</div>
														</div>
													</BoField>
												</div>                                                      
											</div>
										</div>
									</div>
								</div>
								<div class="col-4 mt-3">
									<button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</VForm>
			</div>
		</div>

		<!-- Show Unit -->
		<div class="card" v-if="$route.params.id">
			<div class="card-body">
				<h5 class="card-title">Tipe Unit {{data.mp_title}}</h5>
				<draggable v-model="unit" class="row" @end="endDrag">

					<div style="cursor:move;" class="col-md-3" :id="'dat'+v.mpu_id" v-for="(v,k) in unit" :key="k">
						<div class="wrap_slider_img">
							<img :src="uploader(v.mpu_thumb_img)" class="img-responsive" />
							<div class="slider_name">
								<p>{{v.mpu_jenis}}</p>
							</div>
							<a data-toggle="collapse" @click="editUnit(v)" href="javascript:;"
								class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
							<a href="javascript:;" @click="delUnit(v.mpu_id)" class="bullet_delete"><i
									class="far fa-trash-alt"></i></a>
							<span class="label label-success" v-if="v.mpu_is_active == 'Y'">Active</span>
							<span class="label label-danger" v-else>Inactive</span>
						</div>
					</div>

					<div class="col-md-3">
						<a class="wrap_upload" data-toggle="collapse" @click="addUnit" href="javascript:;"
							role="button" aria-expanded="false" aria-controls="collapseExample">
							<div class="ic_wrap">
								<i class="fas fa-plus"></i>
								<p>New Unit</p>
							</div>
						</a>
					</div>
				</draggable>
				<VForm @resp="submitUnit" method="post">
					<div class="row collapse mt-4" id="collapseExample">
						<div class="col-12 info-unit"></div>
						<div class="col-md-4 mb-3">
							<BoField name="mpu_thumb_img" mandatory>
								<Uploader :param="{thumbnail:true}" name="mpu_thumb_img"
									v-model="inputUnit.mpu_thumb_img" type="thumbunit" uploadType="cropping"></Uploader>
							</BoField>
						</div>
						<div class="col-md-8 mb-3">
							<div class="row">
								<div class="col-md-12">
									<div class="panel_group">
										<div class="panel_heading">Content</div>
										<div class="panel_body">
											<div class="row">
												<div class="col-md-9">
													<BoField name="mpu_jenis" v-model="inputUnit.mpu_jenis" required></BoField>
												</div>
												<div class="col-md-9">
													<BoField name="mpu_tipe" :label="'Type Size'">
														<TagsInput name="mpu_tipe" :placeholder="'Ex: 36/40'"
															style="display:block" v-model="inputUnit.mpu_tipe"
															:attr="validation('mpu_tipe')"></TagsInput>
													</BoField>
												</div>
												<div class="col-md-9">
													<BoField name="mpu_desc">
														<textarea v-model="inputUnit.mpu_desc" rows="4"
															class="form-control"></textarea>
													</BoField>
												</div>
												<div class="col-md-9">
													<BoField name="mpu_is_active">
														<div class="row">
															<radio name="mpu_is_active"
																v-model="inputUnit.mpu_is_active" option="Y"
																:attr="validation('mpu_is_active')">Active</radio>
															<radio name="mpu_is_active"
																v-model="inputUnit.mpu_is_active" option="N">Inactive
															</radio>
														</div>
													</BoField>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12">
									<div class="panel_group">
										<div class="panel_heading">Specification</div>
										<div class="panel_body">
											<div class="row">
												<div class="col-md-3" v-for="(v,k) in inputUnit.spec" :key="k">
													<BoField name="spc_img">
														<Uploader :param="{thumbnail:true}" name="spc_img"
															v-model="v.spc_img" type="adventage"
															uploadType="upload"></Uploader>
													</BoField>
													<BoField name="spc_title" v-model="v.spc_title" required>
													</BoField>
													<a v-if="inputUnit.spec.length>1" href="javascript:;"
														@click="delSpec(k)" class="btn btn-danger btn-block mb-3">Delete
														Specification</a>
												</div>
												<div class="col-md-12 text-right">
													<a href="javascript:;" class="btn btn-success"
														@click="addSpec">Add Specification</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 mt-3 text-right">
									<button type="submit"
										class="btn btn-rounded btn-info btn-loading">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</VForm>
			</div>
		</div>
        <!-- End Show Unit -->
	</div>
</template>